import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState, useContext } from "react";
import styled from "styled-components";
import LogoIcon from "../assets/img/LOGO.png";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { userRequest, publicRequest } from "../requestMethods";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordChecklist from "react-password-checklist";
import { useParams } from "react-router";

export const ResetPassword = () => {
  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [pwAgain, setPWAgain] = useState("");
  const [disable, setDisable] = useState(true);
  const [registerMsg, setRegisterMsg] = useState("");
  const { token } = useParams();

  const forgetPasswordhandleClick = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email,
    };
  };
  const handleClick = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email,
      password: pw,
      token: token,
    };
    try {
      const res = await userRequest.post(
        "/auth/resetpassword/" + token,
        credentials
      );

      if (res.status === 200) {
        login(credentials);
      }
    } catch (err) {
      setRegisterMsg(err.response.data.message);
    }
  };

  const login = async (data) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const resLogin = await publicRequest.post("/auth/login", data);
      dispatch({ type: "LOGIN_SUCCESS", payload: resLogin.data });
      navigate("/p");
      navigate(0);
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };
  return (
    <Box>
      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          設定新密碼
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="信箱"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(newValue) => setEmail(newValue.target.value)}
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="密碼"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(newValue) => setPW(newValue.target.value)}
              value={pw}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              margin="normal"
              fullWidth
              name="password"
              label="重複密碼"
              type="password"
              id="passwordagin"
              autoComplete="new-password"
              onChange={(newValue) => setPWAgain(newValue.target.value)}
              value={pwAgain}
            />
          </Grid>
          <Box sx={{ mt: 3 }}>
            <PasswordChecklist
              rules={["minLength", "number", "match"]}
              minLength={8}
              value={pw}
              valueAgain={pwAgain}
              messages={{
                minLength: "密碼需要超過8個字元",
                number: "密碼需要有數字",
                match: "密碼與重複密碼相同",
              }}
              onChange={(isValid) => {
                setDisable(!isValid);
              }}
            />
          </Box>
          {loading === true ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "stretch",
                mb: 4,
              }}
            >
              <Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={handleClick}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={disable}
                >
                  更新
                </Button>
              </Box>
            </Box>
          )}

          {registerMsg !== "" ? <span>{registerMsg}</span> : <></>}
        </Box>
      </Box>
    </Box>
  );
};
