import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login } from "./pages/Login.jsx";
import { Profile } from "./pages/Profile.jsx";
import { Reservation } from "./pages/Reservation.jsx";
import { Settings } from "./pages/Settings.jsx";
import { Booking } from "./pages/Booking.jsx";
import { Order } from "./pages/Order.jsx";
import { TicketOrder } from "./pages/TicketOrder.jsx";
import { Jump } from "./pages/Jump.jsx";
import { AuthContext } from "./context/AuthContext.js";
import { Test } from "./pages/Test.jsx";
import { Payment } from "./pages/Payment.jsx";
import { TicketManagement } from "./pages/TicketManagement.jsx";
import { ChangeReservation } from "./pages/ChangeReservation.jsx";
import { ResetPassword } from "./pages/ResetPassword.jsx";
import { publicRequest, userRequest } from "./requestMethods";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  return children;
  // const admin = GetUserAuth();
  // console.log(children);
  // if ((await checkUser(user)) === 200) {
  //   return children;
  // } else {
  //   return <Navigate to="/" replace />;
  //   // navigate(0);
  // }
  // if (user && user.isAdmin) {
  // return children;
  // }
};

const checkUser = async (user) => {
  if (user) {
    const res = await userRequest.get("/auth/checkuser/" + user._id);
    return res.status;
  }
  return 401;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route
          path="p"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="r" element={<Reservation />} />
        <Route path="s" element={<Settings />} />
        <Route path="b" element={<Booking />} />
        <Route path="j" element={<Jump />} />
        <Route path="o/:eventid" element={<Order />} />
        <Route path="ticketorder/:ticketuuid" element={<TicketOrder />} />
        <Route path="t" element={<TicketManagement />} />
        <Route path="payment/:id" element={<Payment />} />
        <Route path="b/:id" element={<Booking />} />
        <Route path="resetpassword/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
