import { userRequest, publicRequest } from "../requestMethods";
import dayjs from "dayjs";
import { v1 as uuidv1 } from "uuid";

export const getTimeSlots = async (
  selectedRoom,
  isUpdateOrder,
  user,
  event
) => {
  var calendarID = "";
  if (selectedRoom === "LUNA") {
    calendarID = "630da39c6827ded92ae014ec";
  } else if (selectedRoom === "OLLIE") {
    calendarID = "630daf8f6827ded92ae01518";
  }

  try {
    var timeZoneTransform;
    if (isUpdateOrder === false) {
      const res = await publicRequest.get("/calendars/slots/" + calendarID);
      timeZoneTransform = res.data.filterTimeSlots;
    } else {
      const res = await userRequest.get(
        "/calendars/user/" +
          user?._id +
          "/slots/events/" +
          calendarID +
          "/" +
          event?._id
      );
      timeZoneTransform = res.data.filterTimeSlots;
    }
    return timeZoneTransform;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const generateECdata = (user, order) => {
  // const phoneNumber = user.phoneNumber ? user.phoneNumber : " ";
  // const podcastTitle = user.podcastTitle ? user.podcastTitle : " ";
  // const date =
  //   dayjs(order.startTime).format("YYYY/MM/DD HH:mm") +
  //   dayjs(order.endTime).format("- HH:mm");

  // const room =
  //   order.calendarid === "630da39c6827ded92ae014ec" ? "Luna" : "Ollie";

  // const info = room + " " + date;
  let base_param = {
    MerchantTradeNo: "f0a0d7e9fae1d572bc93", //請帶20碼uid, ex: f0a0d7e9fae1bb72bc93
    MerchantTradeDate: "2017/02/13 15:45:30", //ex: 2017/02/13 15:45:30
    TotalAmount: "168",
    TradeDesc: "測試交易描述",
    ItemName: "測試商品等",
    ReturnURL: "https://koucast-server.herokuapp.com/api/ecpayaio/payment",
    // ChooseSubPayment: '',
    // OrderResultURL: "http://192.168.0.107:3000/api/ecpayaio/payment",
    // NeedExtraPaidInfo: '1',
    // ClientBackURL: 'https://www.google.com',
    // ItemURL: 'http://item.test.tw',
    // Remark: user.username+ " "+ podcastTitle+" "+phoneNumber+" "+info,
    // HoldTradeAMT: '1',
    // StoreID: '',
    // CustomField1: user.username,
    // CustomField2: podcastTitle,
    // CustomField3: phoneNumber,
    // CustomField4: info,
  };
  const v1options = {
    node: [0x01, 0x23, 0x45, 0, 0, 0],
    clockseq: 0x1234,
    msecs: dayjs(),
    nsecs: 5678,
  };

  const uuid =
    uuidv1(v1options).split("-")[0] +
    uuidv1(v1options).split("-")[1] +
    uuidv1(v1options).split("-")[2] +
    uuidv1(v1options).split("-")[3];

  base_param.MerchantTradeNo = uuid;
  base_param.TotalAmount = order.price;
  base_param.MerchantTradeDate = dayjs().format("YYYY/MM/DD HH:mm:ss");
  return base_param;
};

export const GetUserEvents = async (userid) => {
  const res = await userRequest.get("/event/user/" + userid);
  return res.data;
};

export const GetUserReservations = async (userid) => {
  const upComingEvents = [];
  const historyEvents = [];
  try {
    const res = await userRequest.get("/event/user/" + userid);
    // console.log(res);
    const events = res.data;

    events.forEach((event) => {
      if (event.status !== "canceled") {
        if (dayjs(event.startTime).isBefore(dayjs())) {
          historyEvents.push(event);
        } else {
          upComingEvents.push(event);
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
  return { upComingEvents: upComingEvents, historyEvents: historyEvents };
};

export const GetTicket = async (userid, _id) => {
  try {
    const res = await userRequest.get("/ticket/" + userid + "/" + _id);
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const GetUserTickets = async (userid) => {
  try {
    const res = await userRequest.get("/ticket/user/" + userid);
    const userTickets = res.data;
    return userTickets;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const GetEvent = async (eventID) => {
  try {
    const res = await userRequest.get("/event/" + eventID);
    const event = res.data;
    return event;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const GetUserTicketsPoints = (tickets) => {
  let pointsSum = 0;
  if (tickets.length !== 0) {
    tickets.forEach((ticket) => {
      pointsSum = pointsSum + ticket.points;
    });
  }
  return pointsSum;
};

export const generateEvent = (
  promotionCode,
  promotionCodeDiscount,
  selectedRoom,
  userid,
  date,
  time,
  plan,
  paymentValue,
  accountNumber,
  taxid,
  notes,
  podcastTitle,
  selectedTicket
) => {
  let paymentStatus = "未付款";
  if (paymentValue === "ticket") {
    paymentStatus = "已付款";
  }
  let price = 500 * plan;
  if (promotionCodeDiscount > 0) {
    price = Math.round(price * promotionCodeDiscount);
  }
  const events = {
    promotionCode: promotionCode,
    calendarid:
      selectedRoom === "LUNA"
        ? "630da39c6827ded92ae014ec"
        : "630daf8f6827ded92ae01518",
    userid: userid,
    duration: plan,
    startTime: dayjs(dayjs(date).format("YYYY/MM/DD ") + time),
    endTime: dayjs(
      dayjs(dayjs(date).format("YYYY/MM/DD ") + time)
        .add(plan, "hour")
        .format("YYYY/MM/DD H:mm")
    ),
    price: price,
    payment: paymentValue,
    timeStamp: dayjs(),
    status: "none",
    paymentStatus: paymentStatus,
    points: plan,
    ecPay: "",
    taxid: taxid,
    notes: notes,
    podcastTitle: podcastTitle,
    ticket: selectedTicket,
    lockNumber: "",
    wiredAccount: accountNumber,
  };
  return events;
};
