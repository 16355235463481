import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import LogoIcon from "../assets/img/LOGO.png";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { publicRequest, userRequest } from "../requestMethods";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordChecklist from "react-password-checklist";
import { version } from "react";
import liff from "@line/liff";

function RegisterPopup(props) {
  const { onClose, selectedValue, open, line } = props;
  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [pwAgain, setPWAgain] = useState("");
  const [userName, setUserName] = useState("");
  const [podcastTitle, setPodcastTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [registerMsg, setRegisterMsg] = useState("");
  const [disable, setDisable] = useState(true);
  const [lineUserId, setLineUserId] = useState();

  useEffect(() => {
    if (line) {
      setEmail(selectedValue.email);
      setUserName(selectedValue.username);
      setLineUserId(selectedValue.useId);
    }
  }, [line]);

  const handleClose = async (data, login) => {
    onClose(selectedValue);

    if (login) {
      dispatch({ type: "LOGIN_START" });
      try {
        const resLogin = await publicRequest.post("/auth/login", data);
        dispatch({ type: "LOGIN_SUCCESS", payload: resLogin.data });
        navigate("/p");
        navigate(0);
      } catch (err) {
        dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
      }
    }
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const registerHandleClick = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email,
      username: userName,
      podcastTitle: podcastTitle,
      password: pw,
      isAdmin: "false",
      phoneNumber: phone,
      lineid: "123",
    };
    try {
      const res = await publicRequest.post("/auth/register", credentials);
      const data = credentials;
      handleClose(data, true);
    } catch (err) {
      setRegisterMsg(err.response.data.message);
    }
  };
  const data = {};

  return (
    <>
      <Dialog onClose={() => handleClose(data, false)} open={open}>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            註冊會員
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3, ml: 4, mr: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="名稱"
                  name="name"
                  disabled={line}
                  autoComplete="family-name"
                  onChange={(newValue) => setUserName(newValue.target.value)}
                  value={userName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="信箱"
                  name="email"
                  disabled={line}
                  autoComplete="email"
                  onChange={(newValue) => setEmail(newValue.target.value)}
                  value={email}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="podcastTitle"
                  label="節目名稱"
                  name="podcastTitle"
                  autoComplete="podcastTitle"
                  onChange={(newValue) =>
                    setPodcastTitle(newValue.target.value)
                  }
                  value={podcastTitle}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="電話"
                  name="phone"
                  type="number"
                  autoComplete="phone"
                  onChange={(newValue) => setPhone(newValue.target.value)}
                  value={phone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="密碼"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(newValue) => setPW(newValue.target.value)}
                  value={pw}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="重複密碼"
                  type="password"
                  id="passwordagin"
                  autoComplete="new-password"
                  onChange={(newValue) => setPWAgain(newValue.target.value)}
                  value={pwAgain}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <PasswordChecklist
                rules={["minLength", "number", "match"]}
                minLength={8}
                value={pw}
                valueAgain={pwAgain}
                messages={{
                  minLength: "密碼需要超過8個字元",
                  number: "密碼需要有數字",
                  match: "密碼與重複密碼相同",
                }}
                onChange={(isValid) => {
                  setDisable(!isValid);
                }}
              />
            </Box>
            {loading === true ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={registerHandleClick}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={disable}
                >
                  註冊
                </Button>
              </Box>
            )}
            {registerMsg !== "" ? <span>{registerMsg}</span> : <></>}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

function LoginPopup(props) {
  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const { onClose, selectedValue, open, line } = props;
  const [registerMsg, setRegisterMsg] = useState("");
  useEffect(() => {
    if (line) {
      setEmail(selectedValue.email);
    }
  }, [line]);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const forgetPasswordhandleClick = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email,
    };
    try {
      const res = await publicRequest.post("/auth/verifyEmail", credentials);
      if (res.status == 200) {
        setRegisterMsg("重設密碼信件已經信箱");
      }
    } catch (err) {
      setRegisterMsg(err.response.data.message);
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email,
      password: pw,
    };

    dispatch({ type: "LOGIN_START" });
    try {
      const res = await publicRequest.post("/auth/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/p");
      navigate(0);
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };
  return (
    <Box>
      <Dialog onClose={handleClose} open={open}>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, ml: 4, mr: 4 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="信箱"
              name="email"
              disabled={line}
              autoComplete="email"
              autoFocus
              onChange={(newValue) => setEmail(newValue.target.value)}
              value={email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="密碼"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(newValue) => setPW(newValue.target.value)}
              value={pw}
            />
            {loading === true ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "stretch",
                  mb: 4,
                }}
              >
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={handleClick}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    登入
                  </Button>
                </Box>
                <Box sx={{ alignItems: "end" }}>
                  <Button
                    type="submit"
                    variant="text"
                    onClick={forgetPasswordhandleClick}
                    sx={{ mt: 1, mb: 2 }}
                  >
                    忘記密碼
                  </Button>
                </Box>
              </Box>
            )}

            {error && <span>{error.message}</span>}
            {registerMsg !== "" ? <span>{registerMsg}</span> : <></>}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
const ProfileImage = styled.div`
  background-image: url(${LogoIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  min-height: 100vh;
`;

export const Login = () => {
  const { user } = useContext(AuthContext);
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [lifftext, setLifftext] = useState();
  const [linedata, setLineData] = useState();
  const [lineenable, setLineEnable] = useState(false);
  const navigate = useNavigate();
  const { loading, error, dispatch } = useContext(AuthContext);

  useEffect(() => {
    liff.init({ liffId: "1657580462-y3080Gq0" }).then(async () => {
      if (liff.isLoggedIn()) {
        setLineEnable(true);
        const lineid = await liff
          .getProfile()
          .then((profile) => {
            return profile.userId;
          })
          .catch((err) => {
            console.log(err);
          });

        const idToken = await liff.getDecodedIDToken();
        const data = {
          email: idToken.email.toString(),
          username: idToken.name.toString(),
          lineid: lineid,
          password: lineid,
          podcastTitle: "",
        };
        setLineData({
          email: idToken.email.toString(),
          username: idToken.name.toString(),
          useId: lineid,
        });
        dispatch({ type: "LOGIN_START" });

        try {
          const res = await publicRequest.post("/auth/register2", data);
          dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
          navigate("/p");
          navigate(0);
        } catch (err) {
          dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
        }
      } else {
        // liff.login();
      }
    });

    // liff
    //   .init({ liffId: "1657580462-y3080Gq0" })
    //   .then(async () => {
    //     setLifftext(liff.isLoggedIn());
    //     // console.log(liff.isLoggedIn());
    //     if (liff.isLoggedIn()) {
    //       const userid = await liff
    //         .getProfile()
    //         .then((profile) => {
    //           return profile.userId;
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       const idToken = await liff.getDecodedIDToken();
    //       setLineEnable(true);
    //       setLineData({
    //         email: idToken.email.toString(),
    //         username: idToken.name.toString(),
    //         useId: userid,
    //       });

    //       setLifftext(
    //         liff.getLanguage().toString() +
    //           " " +
    //           liff.isInClient().toString() +
    //           " " +
    //           liff.isLoggedIn().toString() +
    //           " " +
    //           idToken.email.toString() +
    //           idToken.name.toString() +
    //           userid
    //       );
    //     }
    //     // else {
    //     // liff.login();
    //     // }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // liff
    //   .init({
    //     liffId: "1657580462-y3080Gq0", // Use own liffId
    //   })
    //   .then(() => {
    //     const idToken = liff.getDecodedIDToken();
    //     liff
    //       .getProfile()
    //       .then((profile) => {
    //         profileid = profile.userId;
    //       })
    //       .catch((err) => {
    //         error = err;
    //       });
    //     setLifftext(
    //       liff.getLanguage().toString() +
    //         " " +
    //         liff.isInClient().toString() +
    //         " " +
    //         liff.isLoggedIn().toString() +
    //         " " +
    //         idToken.email.toString() +
    //         idToken.name.toString() +
    //         profileid.toString() +
    //         error.toString()
    //     );
    //   })
    //   .catch((err) => {
    //     console.log("初始化失敗");
    //   });
  }, []);

  // useEffect(() => {
  //   checkUser();
  // }, []);

  const checkUser = async () => {
    if (user) {
      const res = await userRequest.get("/auth/checkuser/" + user?._id);
      if (res.status === 200) {
        navigate("/p");
        navigate(0);
      }
    }
  };

  const handleClickRegisterOpen = () => {
    setOpenRegister(true);
  };

  const handleRegisterClose = (value) => {
    setOpenRegister(false);
  };

  const handleClickLoginOpen = () => {
    setOpenLogin(true);
  };

  const handleLoginClose = (value) => {
    setOpenLogin(false);
  };

  return (
    <Wrapper>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ProfileImage />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">koucast 錄音室</Typography>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body1"></Typography>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack spacing={2} direction="row">
            {lineenable === true ? (
              <CircularProgress />
            ) : (
              <>
                <Button variant="contained" onClick={handleClickRegisterOpen}>
                  註冊
                </Button>
                <RegisterPopup
                  selectedValue={linedata}
                  line={lineenable}
                  open={openRegister}
                  onClose={handleRegisterClose}
                />
                <Button variant="contained" onClick={handleClickLoginOpen}>
                  登入
                </Button>
                <LoginPopup
                  selectedValue={linedata}
                  line={lineenable}
                  open={openLogin}
                  onClose={handleLoginClose}
                />
              </>
            )}
          </Stack>
        </Box>
      </Container>
    </Wrapper>
  );
};
