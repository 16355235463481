import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import LunaImg from "../assets/img/luna.jpg";
import OllieImg from "../assets/img/ollie.jpg";
import { AuthContext } from "../context/AuthContext.js";
import { userRequest } from "../requestMethods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

export const Order = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { eventid } = useParams();
  const [event, setEvent] = useState({
    calendarid: "",
    startTime: "",
    endTime: "",
    duration: "",
    point: "",
    payment: "",
    paymentStatus: "",
    lockNumber: "",
    status: "",
  });

  const [ticket, setTicket] = useState({
    title: "",
    points: "",
    type: "",
  });
  const [open, setOpen] = React.useState(false);

  const [userValidation, setUserValidation] = useState("none");

  useEffect(() => {
    checkUserValidation(user);
    getEventData();
  }, []);
  useEffect(() => {
    // if (userValidation === "none") {
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation("none");
    } else {
      const res = await userRequest.get("/auth/checkuser/" + user._id);

      if (res.status === 200) {
        setUserValidation("flex");
      } else {
        setUserValidation("none");
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [lockNumber, setLockNumber] = useState("test");

  useEffect(() => {
    getTicketData();
  }, [event]);

  const cancelOrderHandleClicked = () => {
    handleClickOpen();
  };

  const updateHandleClicked = () => {
    if (dayjs(event.startTime).diff(dayjs(), "d") < 3) {
      setOpen(true);
    } else {
      navigate("/b/" + event._id);
    }
  };
  const paymentHandleClicked = () => {
    navigate("/payment/" + event._id + "#event");
  };
  const deleteEventData = async () => {
    const res = await userRequest.delete(
      "/event/user/" + user._id + "/" + eventid
    );
    setOpen(false);
    navigate("/p");
  };

  const getTicketData = async () => {
    if (event.payment === "ticket") {
      const res = await userRequest.get("/ticket/event/" + eventid);
      setTicket(res.data[0]);
    }
  };

  const getEventData = async () => {
    const res = await userRequest.get("/event/" + eventid);

    if (res.data) {
      setEvent(res.data);
      if (user) {
        if (user._id === res.data.userid) {
          setUserValidation("flex");
        } else {
          setUserValidation("none");
        }
      }
    }
  };
  return (
    <Container maxWidth="xs">
      {event.payment === "creditcard" && event.paymentStatus === "已付款" ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"信用卡預約無法取消，請聯絡管理員！"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>確認</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : dayjs(event.startTime).diff(dayjs(), "d") < 3 ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"預約三天前無法取消"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>確認</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"是否確定要取消預約？"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>否</Button>
              <Button onClick={deleteEventData}>是</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      <Box sx={{ p: 2, marginTop: 3 }}>
        <Link to="/p" style={{ color: "#000" }}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </Link>
      </Box>
      <Box
        component="form"
        noValidate
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid>
          <Typography variant="h4">預約資訊</Typography>
        </Grid>
        <Grid
          sx={{
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            感謝您預約了koucast錄音室
          </Typography>
          <Typography variant="body2" color="text.secondary">
            以下是您的預約資訊
          </Typography>
        </Grid>
        <Grid sx={{ bgcolor: "#FFFF", marginTop: 8 }}>
          <Card variant="outlined" sx={{ minWidth: "320px" }}>
            <CardOverflow>
              <AspectRatio ratio="2">
                <img
                  src={
                    event.calendarid === "630da39c6827ded92ae014ec"
                      ? LunaImg
                      : OllieImg
                  }
                  alt=""
                />
              </AspectRatio>
            </CardOverflow>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ mt: 2 }}>
                {event.calendarid === "630da39c6827ded92ae014ec"
                  ? "Luna"
                  : "Ollie"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {dayjs(event.startTime).format("YYYY/MM/DD HH:mm") +
                    "-" +
                    dayjs(event.endTime).format("HH:mm")}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {event.duration}小時錄音
                </Typography>
              </Box>
            </Box>

            <Typography variant="body2" sx={{ mt: 0.5 }}>
              節目名稱：{event.podcastTitle}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              付款方式：
              {event.payment === "creditcard"
                ? "信用卡"
                : event.payment === "cash"
                ? "現金"
                : event.payment === "ticket"
                ? "票券 " + ticket.type + " " + ticket.title
                : "匯款"}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              付款資訊：{event.paymentStatus}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5, mb: 2 }}>
              總計：
              {event.payment === "ticket" ? ticket.points + "點" : event.price}
            </Typography>
            {event.status === "canceled" ? (
              <Typography variant="body" sx={{ mt: 0.5, mb: 2 }}>
                預約已取消
              </Typography>
            ) : (
              <></>
            )}
            {(event.paymentStatus !== "已付款" &&
              event.status !== "canceled") ||
            event.paymentStatus === "3D授權未完成" ? (
              <Box
                sx={{
                  display: userValidation,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="付款"
                  onClick={paymentHandleClicked}
                >
                  付款
                </Button>
              </Box>
            ) : (
              <></>
            )}

            <Box
              sx={{
                display: userValidation,
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              {event.status === "done" || event.status === "canceled" ? (
                <div></div>
              ) : event.payment === "creditcard" ||
                (event.paymentStatus === "已付款" &&
                  event.payment !== "ticket") ? (
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="取消"
                  sx={{}}
                  disabled={true}
                  onClick={cancelOrderHandleClicked}
                >
                  取消
                </Button>
              ) : (
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="取消"
                  sx={{}}
                  onClick={cancelOrderHandleClicked}
                >
                  取消
                </Button>
              )}

              {event.status === "done" || event.status === "canceled" ? (
                <div></div>
              ) : event.payment === "creditcard" ||
                (event.paymentStatus === "已付款" &&
                  event.payment !== "ticket") ? (
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="修改"
                  disabled={true}
                  onClick={updateHandleClicked}
                >
                  修改
                </Button>
              ) : (
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="修改"
                  onClick={updateHandleClicked}
                >
                  修改
                </Button>
              )}
            </Box>
            {event.status === "canceled" ? (
              <></>
            ) : (
              <Box
                sx={{ m: 6 }}
                style={{
                  height: "auto",

                  maxWidth: 220,
                  width: "100%",
                }}
              >
                <Typography variant="body" sx={{ mt: 0.5 }}>
                  預約門禁：
                </Typography>
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 12,
                  }}
                  value={
                    event.lockNumber === ""
                      ? "test"
                      : event.lockNumber.toString()
                  }
                  viewBox={`0 0 256 256`}
                />
                {/* <Typography variant="body" sx={{ mt: 0.5 }}>
                  {event.lockNumber === "" ? "test" : event.lockNumber}
                </Typography> */}
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  預約前30分鐘可以使用QRcode進入koucast
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  台北市信義區基隆路一段141號13樓
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                （警衛換證感應13樓右轉第一間)
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
      </Box>
    </Container>
  );
};
