import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReservationTicketCard } from "../components/ReservationTicketCard";
import { AuthContext } from "../context/AuthContext.js";
import {
  GetUserTickets,
  generateECdata,
  GetTicket,
} from "../funtions/ServerUtilities";
import { TicketCard } from "../components/TicketCard";
import Chip from "@mui/joy/Chip";
import ChipMui from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/joy/Button";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { publicRequest, userRequest } from "../requestMethods";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

export const Payment = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const location = useLocation();
  const [event, setEvent] = useState({});
  const [paymentValue, setPaymentValue] = useState("cash");
  const [title, setTitle] = useState("");
  const [taxid, setTaxid] = useState(user?.taxid || "");
  const [data, setData] = useState("");
  const [price, setPrice] = useState();
  const [ticket, setTicket] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  const [html, setHTML] = useState();
  const [open, setOpen] = useState(false);
  const [userValidation, setUserValidation] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // checkUserValidation(user);
    if (location.hash === "#event") {
      getEventData();
    } else if (location.hash === "#storedvalueticket") {
      getUserTickets();
    } else if (location.hash === "#ticket") {
      getTicket();
    }
  }, []);
  useEffect(() => {
    if (userValidation === false) {
      localStorage.clear();
      window.location.href = "/";
    }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation(false);
    }
    const res = await userRequest.get("/auth/checkuser/" + user._id);

    if (res.status === 200) {
      setUserValidation(true);
    }
    setUserValidation(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (err) => {
    setOpen(false);
    setLoading(false);
    if (err === false) {
      navigate("/p");
    }
  };

  useEffect(() => {
    if (html) {
      document.getElementById("_form_aiochk").submit();
    }
  }, [html]);

  useEffect(() => {
    if (event) {
      const caledar =
        event.calendarid === "630da39c6827ded92ae014ec" ? "LUNA" : "OLLIE";
      setData({
        id: event._id,
        title:
          caledar +
          " " +
          event.duration +
          "小時 " +
          dayjs(event.startTime).format("MM/DD hh:mm"),
        price: event.price,
      });
    }
  }, [event]);

  useEffect(() => {
    if (ticket) {
      setData({
        id: ticket.ticketid,
        title: ticket.type + " " + ticket.title,
        price: ticket.price,
      });
      setTaxid(ticket.taxid);
    }
  }, [ticket]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setPrice(data.price);
    }
  }, [data]);

  const paymentHandleClicked = async () => {
    setLoading(true);
    if (location.hash === "#event") {
      const eventData = {
        payment: paymentValue,
        taxid: taxid,
        wiredAccount: accountNumber,
        status: "check",
        price: price,
      };
      try {
        const eventRes = await userRequest.post(
          "/event/updatepayment/user/" + user._id + "/" + id,
          eventData
        );

        if (paymentValue === "creditcard") {
          const data = {
            base_param: generateECdata(user, eventRes.data),
            id: eventRes.data._id,
            type: "event",
          };
          const res = await publicRequest.post("/ecpayaio/new", data);
          setHTML(res.data);
        } else {
          handleClickOpen();
        }
      } catch (err) {
        console.log(err);
      }
    } else if (location.hash === "#storedvalueticket") {
      const ticketData = {
        userid: user._id,
        ticketid: ticket.ticketid,
        eventDate: dayjs().format("YYYY/MM/DD HH:mm"),
        type: ticket.type,
        payment: paymentValue,
        timeStamp: dayjs(),
        typeStatus: "購買時數",
        status: "check",
        paymentStatus: "未付款",
        points: "+" + (ticket.price / 400).toString(),
        ecPay: "",
        taxid: taxid,
        title: ticket.title,
        price: ticket.price,
        wiredAccount: accountNumber,
      };

      try {
        const ticketRes = await userRequest.post(
          "/ticket/user/" + user._id + "/store",
          ticketData
        );
        if (paymentValue === "creditcard") {
          const data = {
            base_param: generateECdata(user, ticketRes.data),
            id: ticketRes.data._id,
            type: "ticket",
          };
          const res = await publicRequest.post("/ecpayaio/new", data);
          setHTML(res.data);
        } else {
          handleClickOpen();
        }
      } catch (err) {
        console.log(err);
      }
    } else if (location.hash === "#ticket") {
      const ticketData = {
        payment: paymentValue,
        taxid: taxid,
        wiredAccount: accountNumber,
        status: "check",
        price: price,
      };
      try {
        const ticketRes = await userRequest.post(
          "/ticket/updatepayment/user/" + user._id + "/" + id,
          ticketData
        );

        if (paymentValue === "creditcard") {
          const data = {
            base_param: generateECdata(user, ticketRes.data),
            id: ticketRes.data._id,
            type: "ticket",
          };
          const res = await publicRequest.post("/ecpayaio/new", data);
          setHTML(res.data);
          setHTML(res.data);
        } else {
          handleClickOpen();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getTicket = async () => {
    const ticket = await GetTicket(user._id, id);
    setTicket(ticket);
  };

  const getUserTickets = async () => {
    const tickets = await GetUserTickets(user._id);
    let groupIndex = 0;
    if (tickets.length !== 0) {
      tickets.map((ticketGroup, index) => {
        if (ticketGroup[0].ticketid === id) {
          groupIndex = index;
        }
      });
    }
    setTicket(tickets[groupIndex][0]);
  };

  const getEventData = async () => {
    const res = await userRequest.get("/event/" + id);
    setEvent(res.data);
  };

  const handleAccountNumberChange = (event) => {
    if (event.target.value.length <= 5) {
      setAccountNumber(event.target.value);
    }
  };

  const handletaxidValueChange = (event) => {
    setTaxid(event.target.value);
  };

  const handlePaymentValueChange = (event) => {
    setPaymentValue(event.target.value);
  };
  return (
    <Container maxWidth="xs">
      {paymentValue === "wiredtransfer" && accountNumber === "" ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"資料錯誤！"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                請提供正確的匯款帳號資訊。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(true)}>確認</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : paymentValue === "wiredtransfer" ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"付款通知"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                請確認是否正確匯款，三日內管理員會確認資料且更改付款狀態。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(false)}>確認</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : paymentValue === "cash" ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"付款通知"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                請在錄音完結束後付款，管理員會將更改付款狀態。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(false)}>確認</Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <></>
      )}
      <Box sx={{ p: 2, marginTop: 3 }}>
        <Link to="/p" style={{ color: "#000" }}>
          <ArrowBackIosIcon />
        </Link>
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">付款</Typography>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiTextField-root": { m: 3, width: "25ch" },
            bgcolor: "white",
          }}
        >
          <TextField
            id="outlined-read-only-input"
            label="品項"
            value={title}
            disabled
          />
          <TextField
            id="taxnumber"
            label="統編(選填)"
            value={taxid}
            onChange={handletaxidValueChange}
          />

          <FormControl>
            <FormLabel>付款方式</FormLabel>
            <RadioGroup
              row
              value={paymentValue}
              onChange={handlePaymentValueChange}
            >
              <FormControlLabel
                value="creditcard"
                control={<Radio />}
                label="信用卡"
              />
              <FormControlLabel
                value="cash"
                control={<Radio />}
                label="現金 "
              />
              <FormControlLabel
                value="wiredtransfer"
                control={<Radio />}
                label="匯款"
              />
            </RadioGroup>
          </FormControl>
          {paymentValue === "wiredtransfer" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Box>
                <Typography variant="p">帳號 (013) 037031013381</Typography>
              </Box>
              <Box>
                <TextField
                  id="accountnumber"
                  label="帳號末五碼"
                  value={accountNumber}
                  onChange={handleAccountNumberChange}
                  type="number"
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ mt: 4, mb: 6 }}>
            <Typography variant="p">總額： ${price}</Typography>
          </Box>
          {loading === true ? (
            <Box sx={{ bgcolor: "white" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button size="md" onClick={paymentHandleClicked}>
              結帳
            </Button>
          )}
        </Box>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};
