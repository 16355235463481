import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReservationCard } from "../components/ReservationCard";
import { AuthContext } from "../context/AuthContext.js";
import { GetUserReservations } from "../funtions/ServerUtilities";
import { userRequest } from "../requestMethods.js";
import { useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const Reservation = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { user } = useContext(AuthContext);
  const [upComingEvents, setUpComingEvents] = useState([]);
  const [historyEvents, setHistoryEvents] = useState([]);
  const [userValidation, setUserValidation] = useState();

  useEffect(() => {
    // checkUserValidation(user);
    getUserReservations();
  }, []);
  useEffect(() => {
    if (userValidation === false) {
      localStorage.clear();
      window.location.href = "/";
    }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation(false);
    }
    const res = await userRequest.get("/auth/checkuser/" + user._id);

    if (res.status === 200) {
      setUserValidation(true);
    }
    setUserValidation(false);
  };

  const getUserServer = async (user) => {};

  const getUserReservations = async () => {
    const events = await GetUserReservations(user._id);
    setUpComingEvents(events.upComingEvents);
    setHistoryEvents(events.historyEvents);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ p: 2, marginTop: 3 }}>
        <Link to="/p" style={{ color: "#000" }}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </Link>
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h4">預約管理</Typography>
        </Box>
        <Box sx={{ bgcolor: "#f6f6f6", width: 280, marginTop: 2 }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="即將到來" {...a11yProps(0)} />
              <Tab label="歷史預約" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <Box>
              {upComingEvents.map((event, index) => {
                return (
                  <Box key={index} sx={{ marginTop: 2 }}>
                    <ReservationCard event={event} key={index} />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box>
              {historyEvents.map((event, index) => {
                return (
                  <Box key={index} sx={{ marginTop: 2 }}>
                    <ReservationCard event={event} key={index} />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};
