import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Button from "@mui/joy/Button";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext.js";
import {
  GetUserReservations,
  GetUserTickets,
  Login,
} from "../funtions/ServerUtilities";
import { updateUser } from "../requestMethods.js";
import { useNavigate } from "react-router-dom";
import { publicRequest, userRequest } from "../requestMethods.js";
import liff from "@line/liff";

export const Profile = () => {
  const { loading, error, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [upComingEvents, setUpComingEvents] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [userValidation, setUserValidation] = useState();

  useEffect(() => {
    getUserReservations();
    getUserTickets();
  }, []);

  // useEffect(() => {
  //   if (userValidation === false) {
  //     localStorage.clear();
  //     window.location.href = "/";
  //   }
  // }, [userValidation]);

  const checkUserValidation = async () => {
    liff.init({ liffId: "1657580462-y3080Gq0" }).then(async () => {
      if (liff.isLoggedIn()) {
        const lineid = await liff
          .getProfile()
          .then((profile) => {
            return profile.userId;
          })
          .catch((err) => {
            console.log(err);
          });

        const idToken = await liff.getDecodedIDToken();
        const data = {
          email: idToken.email.toString(),
          username: idToken.name.toString(),
          lineid: lineid,
        };
        dispatch({ type: "LOGIN_START" });

        try {
          const res = await publicRequest.post("/auth/register2", data);
          dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
          return true;
        } catch (err) {
          dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
          return false;
        }
      }
    });
  };

  const getUserTickets = async () => {
    if (user?._id) {
      const tickets = await GetUserTickets(user._id);
      let points = 0;
      if (tickets?.length !== 0) {
        tickets.map((ticketGroup, index) => {
          ticketGroup.map((ticket, map) => {
            points = points + Number(ticket.points);
          });
        });
        setUserPoints(points);
      }
    }
  };

  const getUserReservations = async () => {
    if (user?._id) {
      const events = await GetUserReservations(user._id);
      if (events !== undefined && events.length !== 0) {
        setUpComingEvents(events?.upComingEvents);
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 14,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4">{user?.username}</Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Link to="/b" style={{ textDecoration: "none" }}>
              <Button color="primary" size="lg">
                我要預約
              </Button>
            </Link>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 6,
                marginLeft: 0,
              }}
            >
              <Box
                sx={{
                  marginLeft: 4,
                  marginRight: 12,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      {upComingEvents.length}
                    </Typography>
                  </Box>
                  <Link
                    to="/r"
                    style={{ color: "#000", textDecoration: "none" }}
                  >
                    <Box>我的預約</Box>
                  </Link>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography variant="h6">{userPoints}</Typography>
                  </Box>
                  <Box>擁有點數</Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: 5,
                p: 3,
              }}
              bgcolor="#FFFF"
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ marginTop: 1 }}>
                    <Link to="/r" style={{ color: "#000" }}>
                      <CalendarTodayIcon />
                    </Link>
                  </Box>
                  <Box sx={{ marginRight: 10, marginLeft: 1 }}>
                    <Link to="/r" style={{ color: "#000" }}>
                      預約管理{" "}
                    </Link>
                  </Box>
                  <Box sx={{ marginLeft: 6 }}>
                    <Link to="/r" style={{ color: "#000" }}>
                      <ArrowForwardIosIcon />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  {" "}
                  <Box sx={{ marginTop: 1 }}>
                    <Link to="/t" style={{ color: "#000" }}>
                      <CardGiftcardIcon />
                    </Link>
                  </Box>
                  <Box sx={{ marginRight: 10, marginLeft: 1 }}>
                    {" "}
                    <Link to="/t" style={{ color: "#000" }}>
                      票券管理
                    </Link>
                  </Box>
                  <Box sx={{ marginLeft: 6 }}>
                    <Link to="/t" style={{ color: "#000" }}>
                      <ArrowForwardIosIcon />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Box sx={{ marginTop: 1 }}>
                    <Link to="/s" style={{ color: "#000" }}>
                      <ManageAccountsIcon />{" "}
                    </Link>
                  </Box>
                  <Box sx={{ marginRight: 10, marginLeft: 1 }}>
                    {" "}
                    <Link to="/s" style={{ color: "#000" }}>
                      帳號管理{" "}
                    </Link>
                  </Box>
                  <Box sx={{ marginLeft: 6 }}>
                    <Link to="/s" style={{ color: "#000" }}>
                      <ArrowForwardIosIcon />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
              onClick={logout}
            >
              登出
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
