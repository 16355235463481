import axios from 'axios';

// const BASE_URL = "http://localhost:8080/api/";
// const BASE_URL = "http://192.168.0.112:8080/api/";
// const BASE_URL = "http://192.168.0.108:8080/api/";
// const BASE_URL = "https://koucast-server.herokuapp.com/api/";
const BASE_URL = 'https://api2.hankoumedia.com/api/';
// const BASE_URL =
// "http://koucastapi-env.eba-4vug9hhs.ap-northeast-3.elasticbeanstalk.com/api/";
// const TOKEN =
//   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser
//     .accessToken || "";

let user = JSON.parse(localStorage.getItem('user'));
let TOKEN = user?.token;
// const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("user"))).token;

export const updateUser = () => {
  user = JSON.parse(localStorage.getItem('user'));
  TOKEN = user?.token;
  return true;
};

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
});

userRequest.defaults.headers.common['token'] = `Bearer ${TOKEN}`;

// export function userRequest() {
//   const userRequest = axios.create({
//     baseURL: BASE_URL,
//   });
//   const user = JSON.parse(localStorage.getItem("user"));
//   console.log(user);
//   const token = user?.token;
//   userRequest.defaults.headers.common["token"] = `Bearer ${token}`;
//   console.log(typeof userRequest);
//   return userRequest;
// }
//
