import React, { useEffect, useState, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import liff from "@line/liff";
import { publicRequest, userRequest } from "../requestMethods";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styled from "styled-components";
import LogoIcon from "../assets/img/LOGO.png";

const ProfileImage = styled.div`
  background-image: url(${LogoIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const Jump = () => {
  const { loading, error, dispatch } = useContext(AuthContext);
  const [lineenable, setLineEnable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    liff.init({ liffId: "1657580462-B4PyPQzP" }).then(async () => {
      if (liff.isLoggedIn()) {
        setLineEnable(true);
        const lineid = await liff
          .getProfile()
          .then((profile) => {
            return profile.userId;
          })
          .catch((err) => {
            console.log(err);
          });

        const idToken = await liff.getDecodedIDToken();
        const data = {
          email: idToken.email.toString(),
          username: idToken.name.toString(),
          lineid: lineid,
          password: lineid,
          podcastTitle: "",
        };
        dispatch({ type: "LOGIN_START" });

        try {
          const res = await publicRequest.post("/auth/register2", data);
          dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
          navigate("/b");
          navigate(0);
        } catch (err) {
          dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
        }
      } else {
        liff.login();
      }
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ProfileImage />
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">koucast 錄音室</Typography>
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      ></Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};
