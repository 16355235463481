import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AuthContext } from '../context/AuthContext.js';
import { useContext } from 'react';
import { PageNotFound } from './PageNotFound.jsx';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordChecklist from 'react-password-checklist';
import { userRequest, publicRequest } from '../requestMethods';
import { useNavigate } from 'react-router-dom';

export const Settings = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { loading, error, dispatch } = useContext(AuthContext);
  const [email, setEmail] = useState(user?.email);
  const [email2, setEmail2] = useState(user?.email2 || '');
  const [pw, setPW] = useState('');
  const [pwAgain, setPWAgain] = useState('');
  const [userName, setUserName] = useState(user?.username);
  const [podcastTitle, setPodcastTitle] = useState(user?.podcastTitle || '');
  const [phone, setPhone] = useState(user?.phoneNumber || '');
  const [disable, setDisable] = useState(true);
  const [registerMsg, setRegisterMsg] = useState('');
  const [taxid, setTaxid] = useState(user?.taxid || '');
  const [userValidation, setUserValidation] = useState();
  const [emailValid, setEmailValid] = useState(true);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    // checkUserValidation(user);
  }, []);
  useEffect(() => {
    if (userValidation === false) {
      localStorage.clear();
      window.location.href = '/';
    }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation(false);
    }
    const res = await userRequest.get('/auth/checkuser/' + user._id);

    if (res.status === 200) {
      setUserValidation(true);
    }
    setUserValidation(false);
  };

  if (user == null) {
    return <PageNotFound />;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    const credentials = {
      email: email,
      username: userName,
      podcastTitle: podcastTitle,
      phoneNumber: phone,
      taxid: taxid,
      email2: email2,
    };
    try {
      const res = await userRequest.post(
        '/auth/update/' + user._id,
        credentials,
      );

      if (res.status === 200) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });

        navigate('/p');
        navigate(0);
        // login(credentials);
      }
    } catch (err) {
      setRegisterMsg(err.response.data.message);
    }
  };

  const login = async (data) => {
    dispatch({ type: 'LOGIN_START' });
    try {
      const resLogin = await publicRequest.post('/auth/login', data);
      dispatch({ type: 'LOGIN_SUCCESS', payload: resLogin.data });
      navigate('/p');
      navigate(0);
    } catch (err) {
      dispatch({ type: 'LOGIN_FAILURE', payload: err.response.data });
    }
  };

  return (
    <Container maxWidth='xs'>
      <Box sx={{ p: 2, marginTop: 3 }}>
        <Link to='/p' style={{ color: '#000' }}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </Link>
      </Box>
      <Box
        component='form'
        noValidate
        sx={{
          marginTop: 2,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid>
          <Typography variant='h4'>個人設定</Typography>
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 8 }}>
          <TextField
            required
            id='username'
            label='姓名'
            onChange={(newValue) => setUserName(newValue.target.value)}
            value={userName}
            disabled={true}
          />
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 3 }}>
          <TextField
            required
            id='email'
            label='line信箱'
            onChange={(newValue) => setEmail(newValue.target.value)}
            value={email}
            disabled={true}
          />
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 3 }}>
          <TextField
            required
            id='phoneNumber'
            type='number'
            label='電話'
            onChange={(newValue) => setPhone(newValue.target.value)}
            value={phone}
          />
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 3 }}>
          <TextField
            required
            id='podcastTitle'
            label='節目名稱'
            onChange={(newValue) => setPodcastTitle(newValue.target.value)}
            value={podcastTitle}
          />
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 3 }}>
          <TextField
            id='taxid'
            type='taxid'
            label='統編'
            onChange={(newValue) => setTaxid(newValue.target.value)}
            value={taxid}
          />
        </Grid>
        <Grid sx={{ bgcolor: '#FFFF', marginTop: 3 }}>
          <TextField
            error={!emailValid}
            helperText={!emailValid ? '请输入有效的电子邮件地址。' : ''}
            id='email2'
            label='使用中的电子信箱'
            onChange={(event) => {
              const newValue = event.target.value;
              setEmail2(newValue); // 直接设置新值，不立即验证
            }}
            onBlur={() => {
              // 当用户离开输入框时进行验证
              setEmailValid(isValidEmail(email2));
            }}
            value={email2}
          />
        </Grid>
        {/* <Grid sx={{ bgcolor: "#FFFF", marginTop: 3 }}>
          <TextField
            required
            name="password"
            label="密碼"
            type="password"
            id="password"
            autoComplete="new-password"
            onChange={(newValue) => setPW(newValue.target.value)}
            value={pw}
            disabled={true}
          />
        </Grid>
        <Grid sx={{ bgcolor: "#FFFF", marginTop: 3 }}>
          <TextField
            required
            fullWidth
            name="password"
            label="重複密碼"
            type="password"
            id="passwordagin"
            autoComplete="new-password"
            onChange={(newValue) => setPWAgain(newValue.target.value)}
            value={pwAgain}
            disabled={true}
          />
        </Grid>

        <Box sx={{ mt: 3 }}>
          <PasswordChecklist
            rules={["minLength", "number", "match"]}
            minLength={8}
            value={pw}
            valueAgain={pwAgain}
            messages={{
              minLength: "密碼需要超過8個字元",
              number: "密碼需要有數字",
              match: "密碼與重複密碼相同",
            }}
            onChange={(isValid) => {
              setDisable(!isValid);
            }}
          />
        </Box> */}
        {loading === true ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <Button
              fullWidth
              variant='contained'
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
              disabled={!emailValid}
            >
              更新
            </Button>
          </Box>
        )}

        {registerMsg !== '' ? <span>{registerMsg}</span> : <></>}
      </Box>
    </Container>
  );
};
