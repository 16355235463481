import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MobileStepper from "@mui/material/MobileStepper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthContext } from "../context/AuthContext.js";
import {
  generateECdata,
  generateEvent,
  GetEvent,
  getTimeSlots,
  GetUserTickets,
} from "../funtions/ServerUtilities";
import { publicRequest, userRequest } from "../requestMethods";

import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReservationCard } from "../components/ReservationCard";
import MenuItem from "@mui/material/MenuItem";
import LunaImg from "../assets/img/luna.jpg";
import OllieImg from "../assets/img/ollie.jpg";

export const Booking = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { user } = useContext(AuthContext);
  const [event, setEvent] = useState("");
  const { id } = useParams();
  //修改訂單
  const [formTitle, setFormTitle] = useState("歡迎預約");
  const [isUpdateOrder, setIsUpdateOrder] = useState(false);

  const [promotionCode, setPromotionCode] = useState("");

  useEffect(() => {
    if (id) {
      setFormTitle("修改訂單");
      getUpdateEvent(id);
    }
  }, [id]);

  const getUpdateEvent = async (eventID) => {
    const event = await GetEvent(eventID);
    setEvent(event);
    setIsUpdateOrder(true);
  };

  //預約
  const [step, setStep] = useState(0);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [date, setDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [nextStep, setNextStep] = useState(true);
  const [previousStep, setPreviousStep] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [plan, setPlan] = useState(1);
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  //PAYMENT PAGE
  const [paymentValue, setPaymentValue] = React.useState("cash");
  const [podcastTitle, setPodcastTitle] = useState(user?.podcastTitle || "");
  const [taxid, setTaxid] = useState(user?.taxid || "");
  const [notes, setNotes] = useState("");

  //TICKET BOOKING
  const [userTickets, setUserTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [userPoints, setUserPoints] = useState(0);

  //信用卡
  const [html, setHTML] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  //通知訊息
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [timeZoneTransform, setTimeZoneTransform] = useState([]);
  const [nextStepText, setNextStepText] = useState("下一步");

  const [userValidation, setUserValidation] = useState();

  const [error, setError] = useState(false);

  useEffect(() => {
    // checkUserValidation(user);
    getUserTickets(user?._id);
  }, []);
  useEffect(() => {
    if (userValidation === false) {
      localStorage.clear();
      window.location.href = "/";
    }
  }, [userValidation]);

  const handleAccountNumberChange = (event) => {
    if (event.target.value.length <= 5) {
      setAccountNumber(event.target.value);
    }
  };

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation(false);
    }
    const res = await userRequest.get("/auth/checkuser/" + user._id);

    if (res.status === 200) {
      setUserValidation(true);
    }
    setUserValidation(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (time === "") {
      setNextStep(true);
    }
  }, [time]);

  const getUserTickets = async (userID) => {
    const tickets = await GetUserTickets(userID);
    if (tickets.length !== 0) {
      setUserTickets(tickets);
      setSelectedTicket(tickets[0][0]);
      setError(false);
    } else if (tickets === null) {
      setError(true);
    }
  };

  useEffect(() => {
    if (step === 4) {
      setNextStep(true);
      setPreviousStep(true);
    } else if (step === 2 && time === "") {
      setNextStep(true);
    } else if (step > 0) {
      setNextStep(false);
      setPreviousStep(false);
      // getAvailableTimeSlots();
    } else if (step === 0 && selectedRoom !== "") {
      setNextStep(false);
      setPreviousStep(true);
    }

    window.scrollTo(0, 0);
  }, [step]);

  const getAvailableTimeSlots = () => {
    if (timeZoneTransform && timeZoneTransform.length !== 0) {
      var filterTimeSlots = [];
      filterTimeSlots.push({
        date: dayjs(timeZoneTransform[0]).format("YYYY/M/D"),
        timeSlots: [],
      });

      var index = 0;
      var mainIndex = 0;
      var stepIndex = 0;
      const responseFilterTimeSlots = timeZoneTransform;

      for (index = 0; index < responseFilterTimeSlots.length; index++) {
        if (
          filterTimeSlots[mainIndex].date ===
          dayjs(responseFilterTimeSlots[index]).format("YYYY/M/D")
        ) {
          var checkSum = 1;
          for (stepIndex = 1; stepIndex <= plan * 4; stepIndex++) {
            const nextTime = dayjs(responseFilterTimeSlots[index]).add(
              15 * stepIndex,
              "minute"
            );
            if (
              nextTime.isSame(dayjs(responseFilterTimeSlots[index + stepIndex]))
            ) {
              checkSum = 1 & checkSum;
            } else {
              checkSum = 0 & checkSum;
              break;
            }
          }

          if (checkSum === 1) {
            filterTimeSlots[mainIndex].timeSlots.push(
              dayjs(responseFilterTimeSlots[index]).format("H:mm")
            );
          }
        } else {
          mainIndex = mainIndex + 1;
          filterTimeSlots.push({
            date: dayjs(responseFilterTimeSlots[index]).format("YYYY/M/D"),
            timeSlots: [],
            // timeSlots: [dayjs(responseFilterTimeSlots[index]).format("H:mm")],
          });
          index = index - 1;
        }
      }

      setAvailableSlots(filterTimeSlots);
    }
  };

  useEffect(() => {
    if (availableSlots) {
      const day = dayjs(date).format("YYYY/M/D");
      const availableDays = availableSlots.find((e) => e.date === day);
      if (availableDays !== undefined) {
        setTimeSlots(availableDays.timeSlots);
      }
    }
  }, [date, availableSlots]);

  //Count User Ticket points
  useEffect(() => {
    let selectedIndex = 0;
    if (userTickets && userTickets.length > 0) {
      userTickets.forEach((groupTickets) => {
        if (groupTickets[0].ticketid === selectedTicket.ticketid) {
          groupTickets.forEach((ticket) => {
            selectedIndex = selectedIndex + Number(ticket.points);
          });
        }
      });
    }
    setUserPoints(selectedIndex);
  }, [selectedTicket]);

  useEffect(() => {
    if (html) {
      document.getElementById("_form_aiochk").submit();
    }
  }, [html]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClick = (msg) => {
    setMsg(msg);
    if (msg === "票券點數不足") {
      setSeverity("error");
    } else if (msg === "預約成功") {
      setSeverity("success");
    } else if (msg === "系統連線問題，請聯絡管理員") {
      setSeverity("error");
    }

    setOpen(true);
  };

  const handlepodcastTitleValueChange = (event) => {
    setPodcastTitle(event.target.value);
  };

  const handletaxidValueChange = (event) => {
    setTaxid(event.target.value);
  };

  const handleSetPromotionCode = (event) => {
    setPromotionCode(event.target.value);
  };

  const handlenotesValueChange = (event) => {
    setNotes(event.target.value);
  };

  const handlePaymentValueChange = (event) => {
    setPaymentValue(event.target.value);
  };

  const previousStepClick = () => {
    setStep(step - 1);
  };

  const nextStepClick = async () => {
    let err = false;
    if (step === 0) {
      setNextStep(true);
      setPreviousStep(true);
      const timeZoneTransform = await getTimeSlots(
        selectedRoom,
        isUpdateOrder,
        user,
        event
      );
      console.log(timeZoneTransform);
      setNextStep(false);
      setPreviousStep(false);
      setTimeZoneTransform(timeZoneTransform);
    } else if (step === 1) {
      getAvailableTimeSlots();
    } else if (step === 3 && loading === false) {
      try {
        setLoading(true);
        setPreviousStep(true);
        setNextStep(true);
        err = await bookSumbit();
        setLoading(false);
      } catch (err) {
        setPreviousStep(false);
        setLoading(false);
        console.log(err);
      }
    }

    if (err === false) {
      setStep(step + 1);
    }
  };

  const [promotionCodeDiscount, setPromotionCodeDiscount] = useState(0);

  const checkPromotionCode = async () => {
    const res = await userRequest.post("/promotioncode/check", {
      promotionCode: promotionCode,
    });
    if (res.status === 200) {
      setPromotionCodeDiscount(res.data);
    }
  };

  const bookSumbit = async () => {
    let err = false;

    if (paymentValue === "ticket" && userPoints - plan < 0) {
      handleClick("票券點數不足");
      err = true;
      return err;
    }

    const events = generateEvent(
      promotionCode,
      promotionCodeDiscount,
      selectedRoom,
      user._id,
      date,
      time,
      plan,
      paymentValue,
      accountNumber,
      taxid,
      notes,
      podcastTitle,
      selectedTicket
    );
    try {
      if (isUpdateOrder === false) {
        const eventRes = await userRequest.post(
          "/event/user/" + user._id + "/new",
          events
        );
        if (eventRes.status !== 200) {
          err = true;
          handleClick("系統連線問題，請聯絡管理員");
          return err;
        } else if (
          eventRes.status === 200 &&
          eventRes.data === "時段已被預約，請重新預約"
        ) {
          err = true;
          handleClick("時段已被預約，請重新預約");
          return err;
        }

        setEvent(eventRes.data);

        if (paymentValue === "creditcard") {
          const data = {
            base_param: generateECdata(user, eventRes.data),
            id: eventRes.data._id,
            type: "event",
          };
          const res = await publicRequest.post("/ecpayaio/new", data);
          setHTML(res.data);
        }
        handleClick("預約成功");
      } else {
        const eventRes = await userRequest.put(
          "/event/user/" + user._id + "/" + event._id,
          events
        );

        if (eventRes.status !== 200) {
          err = true;
          handleClick("系統連線問題，請聯絡管理員");
          return err;
        } else if (
          eventRes.status === 200 &&
          eventRes.data === "時段已被預約，請重新預約"
        ) {
          err = true;
          handleClick("時段已被預約，請重新預約");
          return err;
        }

        setEvent(eventRes.data);
        handleClick("預約成功");
      }
    } catch (error) {
      err = true;
      handleClick("系統連線問題，請聯絡管理員");
      return err;
    }
    return err;
  };

  const selectRoom = (room) => {
    setSelectedRoom(room);
    setNextStep(false);
  };

  const selectPlan = (plan) => {
    setPlan(plan);
  };

  const selectTime = (time) => {
    setTime(time);
    setNextStep(false);
  };

  function disableWeekends(date) {
    const day = dayjs(date).format("YYYY/M/D");
    return !availableSlots.find((e) => e.date === day);
  }

  const style = {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: 20,
    left: "52%",
    position: "fixed",
  };

  const style2 = {
    margin: 0,
    top: "auto",
    right: "52%",
    bottom: 20,
    left: "auto",
    position: "fixed",
  };

  const timeSlotsDisplay = timeSlots.map((timeSlots, index) => {
    return (
      <Grid
        key={index}
        item
        xs={4}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Card
          sx={{
            border: time === timeSlots ? 1 : 0,
            borderColor: time === timeSlots ? "primary.main" : "",
            height: 40,
            width: 80,
          }}
          onClick={() => {
            selectTime(timeSlots);
          }}
        >
          <Typography
            color="text.secondary"
            sx={{ fontSize: 14, p: 1, marginLeft: 2 }}
          >
            {timeSlots}
          </Typography>
        </Card>
      </Grid>
    );
  });

  const handleBuyTicketTypeChange = (event) => {
    let temp = {};
    if (userTickets.length !== 0) {
      userTickets.forEach((ticketGroup) => {
        ticketGroup.forEach((ticket) => {
          if (ticket.ticketid === event.target.value) {
            temp = ticket;
          }
        });
      });
      setSelectedTicket(temp);
    }
  };

  const handleCalendarPickerChange = (newValue) => {
    setDate(newValue);
    setTime("");
  };

  function BookingSteps() {
    var maxDate = new Date();

    maxDate.setDate(maxDate.getDate() + 60);

    var minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const plans = [
      "1",
      "1.5",
      "2",
      "2.5",
      "3",
      "3.5",
      "4",
      "4.5",
      "5",
      "5.5",
      "6",
    ];

    const planSlots = plans.map((planSlot, index) => {
      return (
        <Box sx={{ marginTop: 2 }} key={index}>
          <Card
            sx={{
              minWidth: 260,
              border: plan == planSlot ? 1 : 0,
              borderColor: plan == planSlot ? "primary.main" : "",
            }}
            onClick={() => selectPlan(planSlot)}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontSize: 20 }}>
                {planSlot} 小時錄音
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                  ${planSlot * 500}元
                </Typography>
                <Checkbox checked={plan == planSlot ? true : false} />
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    });

    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Card
                sx={{
                  border: selectedRoom === "LUNA" ? 1 : 0,
                  borderColor: selectedRoom === "LUNA" ? "primary.main" : "",
                }}
                onClick={() => selectRoom("LUNA")}
              >
                <CardMedia
                  component="img"
                  alt="luna"
                  height="140"
                  image={LunaImg}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    LUNA
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    價格：500元/小時
                  </Typography>
                  <Typography
                    sx={{ mt: 2 }}
                    variant="body2"
                    color="text.secondary"
                  >
                    適合 2-4
                    人錄音，取自拉丁文中「月亮」之意。輕工業風的設計，加上溫暖柔和的黃光，彷彿是坐在咖啡廳中愜意，激發出創作者更多創造與想像。
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  marginTop: 2,
                  border: selectedRoom == "OLLIE" ? 1 : 0,
                  borderColor: selectedRoom == "OLLIE" ? "primary.main" : "",
                }}
                onClick={() => selectRoom("OLLIE")}
              >
                <CardMedia
                  component="img"
                  alt="ollie"
                  height="140"
                  image={OllieImg}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    OLLIE
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    價格：500元/小時
                  </Typography>
                  <Typography
                    sx={{ mt: 2 }}
                    variant="body2"
                    color="text.secondary"
                  >
                    適合2-4 人錄音，也可以是容納 2-8
                    人的會議空間。以明亮寬敞的北歐風打造的 Ollie
                    ，讓你一進入錄音室，心情便感到愉悅放鬆，是有錄影需求的團隊的最佳選擇。
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {planSlots}
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Box sx={{ bgcolor: "#FFFF" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CalendarPicker
                    onChange={handleCalendarPickerChange}
                    date={date}
                    shouldDisableDate={disableWeekends}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginTop: 4 }}>
                <Grid container spacing={2} justifyContent="space-between">
                  {timeSlotsDisplay}
                </Grid>
              </Box>
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              "& .MuiTextField-root": { m: 3, width: "30ch" },
              bgcolor: "white",
            }}
          >
            {loading === true ? (
              <Box sx={{ bgcolor: "white" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TextField
                  id="showname"
                  label="節目名稱"
                  value={podcastTitle}
                  onChange={handlepodcastTitleValueChange}
                />
                <TextField
                  id="taxnumber"
                  label="統編(選填)"
                  value={taxid}
                  onChange={handletaxidValueChange}
                />
                <TextField
                  id="notes"
                  label="備註"
                  value={notes}
                  onChange={handlenotesValueChange}
                />

                <FormControl>
                  <FormLabel>付款方式</FormLabel>
                  <RadioGroup
                    row
                    value={paymentValue}
                    onChange={handlePaymentValueChange}
                  >
                    <FormControlLabel
                      value="cash"
                      control={<Radio />}
                      label="現金"
                    />
                    <FormControlLabel
                      value="creditcard"
                      control={<Radio />}
                      label="信用卡"
                      // disabled={true}
                      // disabled={isUpdateOrder}
                    />
                    <FormControlLabel
                      value="wiredtransfer"
                      control={<Radio />}
                      label="匯款"
                      // disabled={true}
                      // disabled={isUpdateOrder}
                    />
                    <FormControlLabel
                      value="ticket"
                      disabled={selectedTicket === undefined ? true : false}
                      control={<Radio />}
                      label="票券"
                    />
                  </RadioGroup>
                </FormControl>
                {paymentValue === "wiredtransfer" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="p">
                        帳號 (013) 037031013381
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        id="accountnumber"
                        label="帳號末五碼"
                        value={accountNumber}
                        onChange={handleAccountNumberChange}
                        type="number"
                      />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                {paymentValue === "ticket" ? (
                  <></>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        alignItems: "center",
                        "& .MuiTextField-root": { m: 3, width: "22ch" },
                      }}
                    >
                      <TextField
                        id="promotioncode"
                        label="優惠代碼"
                        value={promotionCode}
                        onChange={handleSetPromotionCode}
                        fullWidth={false}
                      />
                      <Button variant="contained" onClick={checkPromotionCode}>
                        輸入
                      </Button>
                    </Box>
                    <Box>
                      {promotionCodeDiscount === -1 ? (
                        "無效優惠代碼"
                      ) : promotionCodeDiscount === -2 ? (
                        "無效的使用時間"
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                )}

                {paymentValue === "ticket" ? (
                  <FormControl
                    variant="outlined"
                    size="medium"
                    sx={{ width: "25ch", mt: 2 }}
                  >
                    <InputLabel>選擇票券</InputLabel>
                    <Select
                      value={selectedTicket.ticketid}
                      label="TicketTitles"
                      onChange={handleBuyTicketTypeChange}
                    >
                      {userTickets.map((groupTicket, index) => {
                        return (
                          <MenuItem key={index} value={groupTicket[0].ticketid}>
                            {groupTicket[0].title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {paymentValue === "ticket" ? (
                  <Box sx={{ mt: 2, mb: 6 }}>
                    <Box sx={{ mt: 0 }}>
                      <Typography variant="p">
                        票券點數： {userPoints}點
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="p">預約點數： {-plan}點</Typography>
                    </Box>
                    <Box sx={{ mt: 4, mb: 2 }}>
                      <Typography variant="subtitle2">
                        三日內無法取消或更改訂單
                      </Typography>
                    </Box>
                  </Box>
                ) : promotionCodeDiscount > 0 ? (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="p">原價： ${plan * 500}</Typography>
                    <Typography variant="p">
                      優惠： ${plan * 500 * promotionCodeDiscount}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ mt: 4, mb: 0 }}>
                      <Typography variant="p">總額： ${plan * 500}</Typography>
                    </Box>
                    <Box sx={{ mt: 4, mb: 2 }}>
                      <Typography variant="subtitle2">
                        三日內無法取消或更改訂單
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        );
      case 4:
        return (
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ReservationCard event={event} />
          </Box>
        );
      default:
        return;
    }
  }

  return (
    <Container maxWidth="xs">
      {user === null ? (
        <h1>請先登入</h1>
      ) : error === true ? (
        <h1>系統似乎有點狀況，請聯絡koucast line官方帳號</h1>
      ) : (
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: 6,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={handleClose}
            message={msg}
            action={action}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          <Box>
            <Typography variant="h4">{formTitle}</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={step}
              sx={{
                backgroundColor: "#f6f6f6",
                minWidth: 200,
                display: "flex",
                justifyContent: "center",
              }}
            />
          </Box>
          <Box>{BookingSteps()}</Box>
          <Box
            sx={{
              marginTop: 3,
              mb: 12,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {
              <>
                <Fab
                  style={style2}
                  variant="extended"
                  color="primary"
                  size="large"
                  sx={{ borderRadius: 8, marginRight: 10 }}
                  disabled={previousStep}
                  onClick={previousStepClick}
                >
                  上一步
                </Fab>

                <Box>
                  <Fab
                    style={style}
                    variant="extended"
                    color="primary"
                    disabled={nextStep}
                    size="large"
                    sx={{ borderRadius: 8 }}
                    onClick={nextStepClick}
                  >
                    {loading === true ? <CircularProgress /> : "下一步"}
                  </Fab>
                </Box>
              </>
            }

            {/* {step === 4 ? (
              <></>
            ) : (
              <>
                <Button
                  variant="contained"
                 
                  size="large"
                  sx={{ borderRadius: 8, marginRight: 10 }}
                  disabled={previousStep}
                  onClick={previousStepClick}
                >
                  上一步
                </Button>
                {loading === true ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    <Button
                      variant="contained"
                      disabled={nextStep}
                      size="large"
                      sx={{ borderRadius: 8 }}
                      onClick={nextStepClick}
                    >
                      {step === 3 ? "預約" : "下一步"}
                    </Button>
                  </Box>
                )}
              </>
            )} */}
          </Box>
        </Box>
      )}

      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};
