import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReservationTicketCard } from "../components/ReservationTicketCard";
import { AuthContext } from "../context/AuthContext.js";
import { GetUserTickets, generateECdata } from "../funtions/ServerUtilities";
import { TicketCard } from "../components/TicketCard";
import Chip from "@mui/joy/Chip";
import ChipMui from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/joy/Button";
import dayjs from "dayjs";
import { publicRequest, userRequest } from "../requestMethods";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const TicketManagement = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [userTickets, setUserTickets] = useState([]);
  const [step, setStep] = useState(0);
  const [ticketIndex, setTicketIndex] = useState(0);
  const [paymentValue, setPaymentValue] = React.useState("cash");
  const [taxid, setTaxid] = useState(user?.taxid);
  const [title, setTitle] = useState(user?.podcastTitle);
  const [buyTicketType, setBuyTicketType] = React.useState("10小時好友方案");
  const [ticketValue, setTicketValue] = useState(4000);
  const [html, setHTML] = useState();
  const [userValidation, setUserValidation] = useState();
  const [loading, setLoading] = useState(false);

  //通知訊息
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [accountNumber, setAccountNumber] = useState("");

  const [ticketWarning, setTicketWarning] = useState(0);

  const [msgBoxOpen, setMsgBoxOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleＭsgClose = () => {
    setMsgBoxOpen(false);
    setStep(2);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClick = (msg) => {
    setMsg(msg);
    if (msg === "複製成功") {
      setSeverity("success");
    } else if (msg === "成功共用票券") {
      setSeverity("success");
    } else if (msg === "不正確票券，請聯絡管理員") {
      setSeverity("error");
    } else if (msg === "請輸入票券名稱") {
      setSeverity("error");
    }

    setOpen(true);
  };

  useEffect(() => {
    // checkUserValidation(user);
    getUserTickets();
  }, []);

  useEffect(() => {
    if (userTickets.length != 0) {
      setTicketWarning(1);
    }
  }, [userTickets]);
  useEffect(() => {
    if (userValidation === false) {
      localStorage.clear();
      window.location.href = "/";
    }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation(false);
    }
    const res = await userRequest.get("/auth/checkuser/" + user._id);

    if (res.status === 200) {
      setUserValidation(true);
    }
    setUserValidation(false);
  };

  useEffect(() => {
    if (html) {
      document.getElementById("_form_aiochk").submit();
    }
  }, [html]);

  const handleBuyTicketTypeChange = (event) => {
    setBuyTicketType(event.target.value);
    if (event.target.value === "10小時好友方案") {
      setTicketValue(4000);
    } else if (event.target.value === "20小時好友方案") {
      setTicketValue(8000);
    } else if (event.target.value === "30小時好友方案") {
      setTicketValue(12000);
    }
  };

  const handleTitleValueChange = (event) => {
    setTitle(event.target.value);
  };

  const [shareTicketid, setShareTicketid] = useState("");
  const handleShareTicketidChange = (event) => {
    setShareTicketid(event.target.value);
  };

  const handletaxidValueChange = (event) => {
    setTaxid(event.target.value);
  };

  const handlePaymentValueChange = (event) => {
    setPaymentValue(event.target.value);
  };

  const handleClickBuyTicket = () => {
    if (ticketWarning === 1) {
      setMsgBoxOpen(true);
    } else {
      setStep(2);
    }
  };

  const handleShareTicket = () => {
    setLoading(false);
    setStep(3);
  };

  const toggleClicked = (index) => {
    setStep(1);
    setTicketIndex(index);
  };

  const getUserTickets = async () => {
    const tickets = await GetUserTickets(user._id);
    setUserTickets(tickets);
  };

  const toggleBackClicked = () => {
    setStep(0);
  };

  const shareTicket = async (ticketid) => {
    setLoading(true);
    try {
      const res = await userRequest.post(
        "/ticket/share/" + user._id + "/" + ticketid,
        {
          userid: user._id,
        }
      );

      if (res.data !== "NONE") {
        handleClick("成功共用票券");

        navigate("/p");
      } else {
        handleClick("不正確票券，請聯絡管理員");
      }
    } catch (err) {
      handleClick("不正確票券，請聯絡管理員");
      console.log(err);
    }
  };

  const handleAccountNumberChange = (event) => {
    if (event.target.value.length <= 5) {
      setAccountNumber(event.target.value);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sumbit = async () => {
    if (title !== "") {
      const ticket = {
        userid: [user._id],
        eventDate: dayjs(),
        type: buyTicketType,
        payment: paymentValue,
        timeStamp: dayjs(),
        typeStatus: "購買時數",
        status: "check",
        paymentStatus: "未付款",
        points: "+" + (ticketValue / 400).toString(),
        ecPay: "",
        taxid: taxid,
        title: title,
        price: ticketValue,
        wiredAccount: accountNumber,
      };
      try {
        const ticketRes = await userRequest.post(
          "/ticket/new/" + user._id,
          ticket
        );

        if (paymentValue === "creditcard") {
          try {
            const data = {
              base_param: generateECdata(user, ticketRes.data),
              id: ticketRes.data._id,
              type: "ticket",
            };
            const res = await publicRequest.post("/ecpayaio/new", data);
            setHTML(res.data);
          } catch (err) {
            console.log(err);
          }
        } else {
          setStep(0);
          getUserTickets();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      handleClick("請輸入票券名稱");
    }
  };

  return (
    <Container maxWidth="xs">
      <div>
        <Dialog
          open={msgBoxOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"購買票券"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              每個節目請購買一次票券
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              若想要儲值，請點選票券旁邊的「儲值票券點數」！
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleＭsgClose()}>確認</Button>
          </DialogActions>
        </Dialog>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        message={msg}
        action={action}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>

      <Box sx={{ p: 2, marginTop: 3 }}>
        {step === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Link to="/p" style={{ color: "#000" }}>
              <ArrowBackIosIcon />
            </Link>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 0 }}>
                <ChipMui
                  onClick={handleShareTicket}
                  variant="outlined"
                  color="primary"
                  label="共用票券"
                />
              </Box>
              <Box>
                <ChipMui
                  onClick={handleClickBuyTicket}
                  variant="outlined"
                  color="primary"
                  label="購買票券"
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <ArrowBackIosIcon onClick={toggleBackClicked} />
        )}
      </Box>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">票券管理</Typography>
        </Box>
        <Box>
          {step === 1 ? (
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <CopyToClipboard text={userTickets[ticketIndex][0].ticketid}>
                <Button
                  variant="outlined"
                  onClick={() => handleClick("複製成功")}
                >
                  複製票券號碼
                </Button>
              </CopyToClipboard>
            </Box>
          ) : (
            <></>
          )}
          {step === 0 ? (
            userTickets.length === 0 ? (
              <></>
            ) : (
              userTickets.map((ticket, index) => {
                return (
                  <Box
                    sx={{
                      mb: 2,
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    key={index}
                  >
                    <TicketCard
                      toggleClicked={() => {
                        toggleClicked(index);
                      }}
                      ticket={ticket}
                      key={index}
                    />
                  </Box>
                );
              })
            )
          ) : step === 1 ? (
            userTickets[ticketIndex]
              .slice(0)
              .reverse()
              .map((ticket, index) => {
                if (ticket.status !== "canceled") {
                  return (
                    <Box key={index} sx={{ mb: 2 }}>
                      <ReservationTicketCard key={index} ticket={ticket} />
                    </Box>
                  );
                }
              })
          ) : step === 2 ? (
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                "& .MuiTextField-root": { m: 3, width: "25ch" },
                bgcolor: "white",
              }}
            >
              <FormControl
                variant="outlined"
                size="medium"
                sx={{ width: "25ch", mt: 2 }}
              >
                <InputLabel>選擇方案</InputLabel>
                <Select
                  value={buyTicketType}
                  label="BuyTicketType"
                  onChange={handleBuyTicketTypeChange}
                >
                  <MenuItem value={"10小時好友方案"}>10小時好友方案</MenuItem>
                  {/* {   <MenuItem value={"20小時好友方案"}>20小時好友方案</MenuItem>
                  <MenuItem value={"30小時好友方案"}>30小時好友方案</MenuItem>} */}
                </Select>
              </FormControl>
              <TextField
                id="title"
                label="票券名稱"
                value={title}
                onChange={handleTitleValueChange}
              />
              <TextField
                id="taxnumber"
                label="統編(選填)"
                value={taxid}
                onChange={handletaxidValueChange}
              />

              <FormControl>
                <FormLabel>付款方式</FormLabel>
                <RadioGroup
                  row
                  value={paymentValue}
                  onChange={handlePaymentValueChange}
                >
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="現金"
                  />
                  <FormControlLabel
                    value="creditcard"
                    control={<Radio />}
                    label="信用卡"
                  />
                  <FormControlLabel
                    value="wiredtransfer"
                    control={<Radio />}
                    label="匯款"
                  />
                </RadioGroup>
              </FormControl>
              {paymentValue === "wiredtransfer" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Box>
                    <Typography variant="p">帳號 (013) 037031013381</Typography>
                  </Box>
                  <Box>
                    <TextField
                      id="accountnumber"
                      label="帳號末五碼"
                      value={accountNumber}
                      onChange={handleAccountNumberChange}
                      type="number"
                    />
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              <Box sx={{ mt: 4, mb: 6 }}>
                <Typography variant="p">總額： ${ticketValue}</Typography>
              </Box>
              <Button onClick={sumbit} size="md">
                結帳
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: 6,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                "& .MuiTextField-root": { m: 3, width: "25ch" },
                bgcolor: "white",
              }}
            >
              <TextField
                id="ticketid"
                label="票券編號"
                value={shareTicketid}
                onChange={handleShareTicketidChange}
              />

              <Button
                disabled={loading}
                onClick={() => shareTicket(shareTicketid)}
                size="md"
              >
                共用
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
};
