import React, { useState, useEffect } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import Chip from "@mui/material/Chip";
import Typography from "@mui/joy/Typography";
import LogoIcon from "../assets/img/LOGO.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";
export const TicketCard = (data) => {
  // console.log(data.ticket);
  // const title = "";
  // const type = "";
  const navigate = useNavigate();
  const toggleClicked = data.toggleClicked;
  const tickets = data.ticket;
  const title = tickets[0].title;
  const type = tickets[0].type;

  const [points, setPoints] = useState(0);

  const addpointsHandleClick = () => {
    navigate("/payment/" + tickets[0].ticketid + "#storedvalueticket");
  };

  useEffect(() => {
    let points = 0;
    tickets.forEach((ticket) => {
      points = points + Number(ticket.points);
    });

    setPoints(points);
  }, []);
  return (
    <Card
      variant="outlined"
      row
      sx={{
        minWidth: "320px",
        gap: 2,
        "&:hover": {
          boxShadow: "md",
          borderColor: "neutral.outlinedHoverBorder",
        },
      }}
    >
      <AspectRatio ratio="1" sx={{ width: 90 }}>
        <img src={LogoIcon} />
      </AspectRatio>
      <Box>
        <Box sx={{ ml: 0.5 }}>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography level="h" fontSize="lg" id="card-description" mb={0.5}>
              {type}
            </Typography>

            <ArrowForwardIosIcon onClick={toggleClicked} />
          </Box>
          <Typography fontSize="sm" aria-describedby="card-description" mb={1}>
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              fontSize="sm"
              aria-describedby="card-description"
              mb={1}
            >
              {points} 點
            </Typography>

            <Chip
              label="儲值票券"
              variant="outlined"
              color="error"
              size="sm"
              sx={{ ml: 14 }}
              onClick={addpointsHandleClick}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
