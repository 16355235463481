import React from "react";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";

export const ReservationCard = (data) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const event = data.event;

  if (event === " ") return <div></div>;

  return (
    <Link to={"/o/" + event._id} style={{ textDecoration: "none" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        bgcolor="#FFFFFF"
      >
        <Card
          row
          variant="outlined"
          sx={{
            minWidth: "260px",
            gap: 2,
            bgcolor: "background.body",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <CardContent>
            <Typography fontWeight="md" mb={0.5}>
              {event.calendarid === "630da39c6827ded92ae014ec"
                ? "LUNA"
                : "OLLIE"}
            </Typography>
            <Typography level="body2">
              {" "}
              {dayjs(event.startTime)
                .tz("Asia/Taipei", true)
                .format("YYYY/MM/DD HH:mm") +
                "-" +
                dayjs(event.endTime).tz("Asia/Taipei", true).format("HH:mm")}
            </Typography>
            <Typography level="body2"> {event.duration} 小時錄音</Typography>
          </CardContent>
          {event.paymentStatus === "未付款" ? (
            <CardOverflow
              variant="soft"
              color="danger"
              sx={{
                px: 0.2,
                writingMode: "vertical-rl",
                textAlign: "center",
                fontSize: "xs2",
                fontWeight: "xl2",
                letterSpacing: "1px",
                textTransform: "uppercase",
              }}
            >
              {event.paymentStatus}
            </CardOverflow>
          ) : (
            <CardOverflow
              variant="soft"
              color="success"
              sx={{
                px: 0.2,
                writingMode: "vertical-rl",
                textAlign: "center",
                fontSize: "xs2",
                fontWeight: "xl2",
                letterSpacing: "1px",
                textTransform: "uppercase",
              }}
            >
              {event.paymentStatus}
            </CardOverflow>
          )}
        </Card>
      </Box>
    </Link>
  );
};
