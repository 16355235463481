import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import LunaImg from "../assets/img/luna.jpg";
import OllieImg from "../assets/img/ollie.jpg";
import { AuthContext } from "../context/AuthContext.js";
import { userRequest } from "../requestMethods";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

export const TicketOrder = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { ticketuuid } = useParams();
  const [event, setEvent] = useState({
    eventDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    point: "",
    payment: "",
    paymentStatus: "",
    lockNumber: "",
    status: "",
  });

  const [ticket, setTicket] = useState({
    _id: "",
    ecPay: "",
    eventDate: "",
    payment: "",
    paymentStatus: "",
    points: "",
    price: 0,
    status: "",
    ticketid: "",
    timeStamp: "",
    title: "",
    type: "",
    typeStatus: "",
  });
  const [open, setOpen] = React.useState(false);

  const [userValidation, setUserValidation] = useState("none");

  useEffect(() => {
    checkUserValidation(user);
    getTicketData();
  }, []);
  useEffect(() => {
    // if (userValidation === "none") {
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
  }, [userValidation]);

  const checkUserValidation = async (user) => {
    if (user === undefined || user === null) {
      setUserValidation("none");
    } else {
      const res = await userRequest.get("/auth/checkuser/" + user._id);

      if (res.status === 200) {
        setUserValidation("flex");
      } else {
        setUserValidation("none");
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelOrderHandleClicked = () => {
    handleClickOpen();
  };

  const updateHandleClicked = () => {
    if (dayjs(event.startTime).diff(dayjs(), "d") < 3) {
      setOpen(true);
    } else {
      navigate("/b/" + event._id);
    }
  };
  const paymentHandleClicked = () => {
    navigate("/payment/" + ticket._id + "#ticket");
  };

  const deleteEventData = async () => {
    const res = await userRequest.delete(
      "/event/user/" + user._id + "/" + ticketuuid
    );
    setOpen(false);
    navigate("/p");
  };

  const getTicketData = async () => {
    const res = await userRequest.get("/ticket/" + user._id + "/" + ticketuuid);
    if (res.status === 200) {
      setTicket(res.data);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ p: 2, marginTop: 3 }}>
        <Link to="/p" style={{ color: "#000" }}>
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </Link>
      </Box>
      <Box
        component="form"
        noValidate
        sx={{
          marginTop: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid>
          <Typography variant="h4">票券資訊</Typography>
        </Grid>
        <Grid
          sx={{
            marginTop: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            感謝您購買koucast錄音室的票券
          </Typography>
          <Typography variant="body2" color="text.secondary">
            以下是您的票券資訊
          </Typography>
        </Grid>
        <Grid sx={{ bgcolor: "#FFFF", marginTop: 8 }}>
          <Card variant="outlined" sx={{ minWidth: "320px" }}>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              {ticket.type}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              票券名稱：{ticket.title}
            </Typography>

            <Typography variant="body2" sx={{ mt: 0.5 }}>
              購買時間：{dayjs(ticket.eventDate).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              使用：{ticket.typeStatus}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              付款方式：
              {ticket.payment === "creditcard"
                ? "信用卡"
                : ticket.payment === "cash"
                ? "現金"
                : ticket.payment === "wiredtransfer"
                ? "匯款"
                : ""}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              信用卡付款編號：{ticket.ecPay}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              付款資訊：{ticket.paymentStatus}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              點數：{ticket.points}
            </Typography>
            <Typography variant="body2" sx={{ mt: 0.5, mb: 2 }}>
              總計：{ticket.price}
            </Typography>

            {ticket.paymentStatus === "未付款" &&
            ticket.status !== "canceled" ? (
              <Box
                sx={{
                  display: userValidation,
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="soft"
                  size="md"
                  color="neutral"
                  aria-label="付款"
                  onClick={() => paymentHandleClicked()}
                >
                  付款
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Card>
        </Grid>
      </Box>
    </Container>
  );
};
